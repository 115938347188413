<template>
  <div id="modal__home" class="modal_home">
    <div class="content_modal_home">
      <button class="close__modal" @click="$emit('close-popup')"></button>
      <h3 v-if="isAuthenticated" class="title_modal">
        {{ $t("hello") }}, {{ user.first_name }}!
      </h3>
      <h3 v-else class="title_modal">{{ $t("hello") }}!</h3>
      <div class="like_block">
        <img alt="love_icon" src="/img/icon.svg" />
        <img alt="love_icon" src="/img/icon.svg" />
        <img alt="love_icon" src="/img/icon.svg" />
      </div>
      <p class="title_mini__modal">{{ $t("how_mode") }}?</p>

      <div class="btn_block__modal">
        <button class="nav__btn" @click="setMode(false)">
          {{ $t("delivery_at_home") }}
        </button>
        <button
          v-if="cart.is_pickup_available"
          class="nav__btn"
          @click="setMode(true)"
        >
          {{ $t("pickup_at_restaurant") }}
        </button>
      </div>
      <p class="discont__text">{{ $t("special_discount") }}:</p>
      <p class="special_info_text">
        {{ $t("special_discount_desc_p1") }}<br />
        {{ $t("special_discount_desc_p2") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState("auth", ["isAuthenticated", "user"]),
    ...mapState("cart", ["cart"])
  },

  methods: {
    ...mapActions("cart", ["setIsPickup"]),
    async setMode(value) {
      await this.setIsPickup(value);
      this.$cookies.set("lock_mode", true, Infinity);
      this.$emit("close-popup");
    }
  }
};
</script>
