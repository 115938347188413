<template>
  <div :class="['item__pr', gridView ? 'col-6' : 'col-12']">
    <div v-if="showError" id="modal__sorry" class="modal_home">
      <div class="content_modal_home">
        <button class="close__modal" @click="showError = false"></button>
        <div class="warring_text">
          {{ $t("need_authorize") }}
        </div>
        <div class="btn_block__modal mb-0">
          <button class="nav__btn" @click="showError = false">
            OK
          </button>
        </div>
      </div>
    </div>
    <router-link
      :to="{
        name: 'restaurant',
        params: { restaurant: restaurant.slug }
      }"
      class="item_product"
    >
      <div class="img_product">
        <div v-if="restaurant.splash" class="card_splash">
          {{ restaurant.splash.content }}
        </div>
        <img :src="'/storage/' + restaurant.image_main" alt="" class="mw-100" />
        <template>
          <div v-if="getClosestOrderTime(restaurant)" class="info__product">
            <span>{{ getClosestOrderTime(restaurant) }}</span>
            {{ $t("earliest_delivery") }}
          </div>
          <div v-else class="info__product">
            {{ $t("pre_order_for_tomorrow") }}
          </div>
        </template>
        <button
          :class="['favorits_btn__modal', { active: isFavourite }]"
          @click.stop.prevent="toggleFavourite"
        ></button>
      </div>
      <p class="name__product">
        {{ restaurant.name }}
      </p>
      <p class="info_product" v-html="restaurant.description"></p>
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  props: ["restaurant", "gridView"],

  data() {
    return {
      errors: [],
      showError: false,
      error: ""
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("cart", ["isPickup"]),
    isHardError() {
      return Object.prototype.hasOwnProperty.call(
        this.errors.errors,
        "restaurant"
      );
    },
    isFavourite() {
      if (this.user !== null)
        return this.user.favourite_restaurants.some(
          fav => fav.slug === this.restaurant.slug
        );
      else return false;
    }
  },

  methods: {
    ...mapActions("auth", ["me"]),
    getClosestOrderTime() {
      return this.isPickup
        ? this.restaurant.closest_order_time_pickup
        : this.restaurant.closest_order_time_delivery;
    },
    toggleFavourite() {
      this.$http
        .post("/api/user/favourites", {
          resource: "restaurant",
          resource_id: this.restaurant.slug
        })
        .then(() => {
          this.me();
          this.showError = false;
        })
        .catch(error => {
          this.showError = true;
          this.error = error.response.message;
        });
    }
  }
};
</script>
