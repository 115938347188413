<template>
  <div>
    <div v-if="showNewsletterSuccess" id="modal__sorry" class="modal_home">
      <div class="content_modal_home">
        <button
          class="close__modal"
          @click="showNewsletterSuccess = false"
        ></button>
        <div class="warring_text">
          Success!
        </div>
        <div class="btn_block__modal mb-0">
          <button class="nav__btn" @click="showNewsletterSuccess = false">
            OK
          </button>
        </div>
      </div>
    </div>
    <NewPopup
      v-show="isNewPopupOpened"
      @close-popup="closeNewPopup"
    />
    <SelectModePopup
        v-show="isSelectModePopupOpened"
        @close-popup="closeSelectModePopup"
    />

    <div>
      <div class="tabs_block">
        <a
          :class="['items__tabs', { active: !isPickup }]"
          @click="updateIsPickup(false)"
        >
          {{ $t("delivery_at_home") }}
        </a>
        <a
          :class="['items__tabs', { active: isPickup }]"
          @click="updateIsPickup(true)"
          v-if="cart.is_pickup_available"
        >
          {{ $t("pickup_on_site") }}
        </a>
      </div>
    </div>
    <section>
      <div class="container">
        <div class="mobail__tabs">
          <button
            id="mini_prod"
            :class="['mini_prod', { active: gridView === true }]"
            @click="gridView = true"
          ></button>
          <img alt="" src="/img/line.svg" />
          <button
            id="full_prod"
            :class="['full_prod', { active: gridView === false }]"
            @click="gridView = false"
          ></button>
        </div>
      </div>
    </section>
    <section
      v-for="(type, index) in restaurants"
      id="new"
      :key="type.id"
      :style="{ backgroundColor: index % 2 === 1 ? '#EDE9DD' : '#FFFFFF' }"
    >
      <div class="container">
        <h3 class="title__block">
          {{ type.name }}
          <span>
            {{ type.sub_name }}
          </span>
        </h3>
        <div class="row">
          <template v-for="restaurant in type.restaurants">
            <RestaurantCard
              :key="restaurant.id"
              :grid-view="gridView"
              :restaurant="restaurant"
            />
          </template>
        </div>
      </div>
    </section>

    <section id="about">
      <div class="container">
        <h3 class="title_about">
          {{ $t("about_delovery") }}
        </h3>
        <p class="text__about">
          <span>{{ $t("about_delovery_p1") }}</span> <br />
          {{ $t("about_delovery_p2") }}<br />
          {{ $t("about_delovery_p3") }}<br />
          {{ $t("about_delovery_p4") }}
        </p>
      </div>
    </section>

    <section id="subscribe_main">
      <div class="container">
        <div class="subscribe__container">
          <h3>Subscribe to our newsletter</h3>
          <div class="input_block__subscribe">
            <input
              v-model="newsletterEmail"
              class="subscribe__input"
              placeholder="your name@email.com"
              type="text"
            />
            <button
              class="btn_subscribe"
              type="submit"
              @click="subscribeToNewsletter"
            >
              ок
            </button>
          </div>
          <div class="social__main">
            <a
              class="item__social"
              href="https://www.instagram.com/delovery_mc/"
            >
              <img alt="" src="/img/instagram_b.svg" />
            </a>
            <a
              class="item__social"
              href="https://www.riccardogiraudisounddesign.com/"
            >
              <img alt="" src="/img/social_main.svg" />
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import NewPopup from "@/components/NewPopup";
import SelectModePopup from "@/components/SelectModePopup";
import RestaurantCard from "@/components/RestaurantCard";

export default {
  components: {
    NewPopup,
    SelectModePopup,
    RestaurantCard
  },

  data() {
    return {
      gridView: true,
      restaurants: [],
      cuisines: [],
      isSelectModePopupOpened: false,
      isNewPopupOpened: false,
      newsletterEmail: "",
      showNewsletterSuccess: false
    };
  },

  computed: {
    ...mapState("cart", ["cart"]),
    ...mapGetters("cart", ["isPickup"])
  },

  mounted() {
    if (this.$cookies.get("new_popup") !== "true") {
      this.isNewPopupOpened = true;
    }
    if (this.$cookies.get("lock_mode") !== "true") {
      this.isSelectModePopupOpened = true;
    }
  },

  methods: {
    ...mapActions(["setHero", "setSliders"]),
    ...mapActions("cart", ["setIsPickup"]),
    getRestaurants(params = {}) {
      this.$http.get(`/api/restaurants`, { params }).then(response => {
        this.restaurants = response.data.data;
        this.cuisines = response.data.meta.cuisines;
      });
    },
    getClosestOrderTime(restaurant) {
      return this.isPickup
        ? restaurant.closest_order_time_pickup
        : restaurant.closest_order_time_delivery;
    },
    async closeSelectModePopup() {
      await this.getRestaurants();
      this.isSelectModePopupOpened = false;
    },
    async closeNewPopup() {
      this.$cookies.set("new_popup", true, Infinity);
      this.isNewPopupOpened = false;
    },
    async updateIsPickup(value) {
      await this.setIsPickup(value);
      await this.getRestaurants();
    },
    subscribeToNewsletter() {
      this.$http.post(`/api/newsletter`, this.newsletterEmail).then(() => {
        this.newsletterEmail = "";
        this.showNewsletterSuccess = true;
      });
    }
  },

  async beforeRouteEnter(to, from, next) {
    const restaurants = await Vue.axios.get("/api/restaurants");
    const hero = await Vue.axios.get("/api/homepage-hero");
    const sliders = await Vue.axios.get("/api/homepage-sliders");

    next(vm => {
      vm.restaurants = restaurants.data.data;
      vm.cuisines = restaurants.data.meta.cuisines;
      vm.setHero(hero.data.data);
      vm.setSliders(sliders.data.data);
    });
  },

  metaInfo() {
    return {
      title: this.$t("seo.homepage.title"),
      meta: [
        {
          name: "description",
          content: this.$t("seo.homepage.description")
        },
        {
          name: "keywords",
          content: this.$t("seo.homepage.keywords")
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~swiper/css/swiper.css";
</style>
