<template>
  <div id="modal__home__new" class="modal_home modal_home_new">
    <div class="content_modal_home">
      <button class="close__modal" @click="$emit('close-popup')"></button>
      <h3 v-if="isAuthenticated" class="title_modal">
        {{ $t("hello") }}, {{ user.first_name }}!
      </h3>
      <h3 v-else class="title_modal">{{ $t("hello") }}!</h3>
      <div class="like_block">
        <img alt="love_icon" src="/img/icon.svg" />
        <img alt="love_icon" src="/img/icon.svg" />
        <img alt="love_icon" src="/img/icon.svg" />
      </div>

      <div class="fr_text">
        Chers clients,<br /><br /><br />
        Afin de mieux vous servir et faciliter vos paiements sur notre site, nous changeons de système bancaire. <br /><br />
        Ce changement peut entrainer la suppression de vos cartes sauvegardées.<br />
        Veuillez nous excuser pour ce désagrément.<br /><br />
        L’équipe Delovery
      </div>
      <div class="eng_text">
        Dear clients,<br /><br /><br />
        In order to serve you better and facilitate your payments on our site, we are changing our banking system. <br /><br />
        This change may result in the deletion of your saved cards.<br /><br />
        We apologize for any inconvenience. <br /><br />
        Delovery team
      </div>
      <div class="btn_block__modal">
        <button class="nav__btn" @click="$emit('close-popup')">
          Ok
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("auth", ["isAuthenticated", "user"])
  }
};
</script>